.logo-graph {
    margin-bottom: 45px;

    .logo {
        height: 38px;
        width: 158px;
    }

    .elipse {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        border-radius: 50%;
        filter: drop-shadow(0px 20px 35px rgba(0, 0, 0, 0.071));

        &.sm {
            height: 220px;
            width: 220px;
        }

        &.md {
            height: 310px;
            width: 310px;
        }

        &.lg {
            height: 400px;
            width: 400px;
        }
    }
}