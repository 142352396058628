// Style for instruction card

.leftTd{
    opacity: 0.5;
    text-align: left;
    
    font-size: 12px;
    padding: 15px;
    padding-right: 20px;
  }
  
  .rightTd{
    opacity: 0.5;
    text-align: right;
    
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 15px;
  }
  
  .rows{
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
  }
  
  .bottomRow{
    border-style: none;
  }
  
  .table{
    width: 100%
  }





//Style for ShowInstructions
.headerTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 0;
    color: #5271ff;
    
  }
  .headerSubtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #979797;
    
  }

  .passCode{
    margin-top: 10px;
    
    font-weight: 800;
    color: #4C4D52;
  }
  .leftTdShow{
    opacity: 0.5;
    text-align: left;
    
    font-size: 12px;
    padding: 15px;
    padding-right: 50px;
  }
  
  .rightTdShow{
    opacity: 0.5;
    text-align: right;
    
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 300px;
    padding-right: 15px;
  }
  .leftTdShowCode{
    opacity: 0.5;
    text-align: left;
    
    font-size: 14px;
    padding: 15px;
    padding-right: 50px;
  }

  
  .rightTdShowCode{
    opacity: 0.5;
    text-align: right;
    
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 350px;
    padding-right: 15px;
  }
  .rightTdShowInst{
    opacity: 0.5;
    text-align: right;
    
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 250px;
    padding-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 350px;
    display: block;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
  }
  
  .button {
    display: flex;
    gap: 12px;
    justify-content: center;
    width: 100%;
  }
  .container {
    max-width: 100%;
    margin-inline: auto;
    margin-top: 50px;
  }

  .timeShow{
    
    font-size: 14px;
    font-weight: 500;
  }

  .circle {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 24px;
    line-height: 31px;
    width: 61px;
    aspect-ratio: 1;
    border-radius: 100px;
    font-weight: 700;
    background-color: #5271ff;
    position: relative;
    bottom: 50px; 
    left: 15px; 
}

.infoRow {
    
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: #4C4D52;
}

.circleFrame {
    width: 250px; 
    height: 250px; 
    border-radius: 50%; 
    overflow: hidden; 
    border: 3px solid #5271ff; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
}

.circleFrame img {
    width: 100%; 
    height: 100%; 
    display: block; 
    object-fit: cover;
}
.switchbot-smallCircleFrame{
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  overflow: hidden; 
  border: 2px solid #5271ff; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 65%; 
  left: 60%; 
  transform: translate(-50%, -50%);
}

.august-smallCircleFrame{
  width: 60px; 
  height: 60px;
  border-radius: 50%; 
  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 73%; 
  left: 35%; 
  transform: translate(-50%, -50%);
}

.yale-smallCircleFrame{
  width: 50px; 
  height: 50px;
  border-radius: 50%; 
  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 70%; 
  left: 60%; 
  transform: translate(-50%, -50%);
}

.switchbot-smallLeftFrame{
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  overflow: hidden; 
  border: 2px solid #5271ff; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 65%; 
  left: 40%; 
  transform: translate(-50%, -50%);
}
.august-smallLeftFrame{
  width: 66px; 
  height: 66px; 
  border-radius: 50%; 
  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 81%; 
  left: 56%; 
  transform: translate(-50%, -50%);
}
.yale-smallLeftFrame{
  width: 60px; 
  height: 60px; 
  border-radius: 50%; 
  overflow: hidden; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 20%; 
  left: 52%; 
  transform: translate(-50%, -50%);
}

.stepGuide {
  max-width: 250px; 
  text-align: center; 
  margin: 10px auto; 
  
  font-weight: 400;
  font-size: 15px;
}

.helpInfo{
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  color: rgb(0, 0, 0, 0.6);
  
}
.helpInfo img{
  height: 12px;
  width: 12px;
  margin-right: 5px;
}
.backArrow:hover{
  cursor: pointer;
}

.moreInfo{
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  color: rgb(0, 0, 0, 0.6);
  
}

.asText {
  background:none;
  border:none;
  margin:0;
  padding:0;
  
  font-size: 10px;
  text-decoration: underline;
  color: #5271ff;
  text-transform: uppercase;
}
.asSmallText {
  background:none;
  border:none;
  margin:0;
  padding:0;
  
  font-size: 10px;
  color: #5271ff;
}
.instructContainer{
  max-width: 650px;
}
.imgContainer{
  display: flex;
  gap: 35px;
}

.imgSize{
  width: 25%;
  border-radius: 20px;
  padding: 10px;
}

.icon{
  width: 14px;
  height: 14px;
}
.icon2{
  width: 14px;
  height: 14px;
}

.stepContainer{
  display: flex;
  flex-direction: row;
  gap:40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(5px); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.modalContent {
  background: white;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 596px; 
}
.expiredContent {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #8C8C8C;
  
  font-size: 14px;
}

.cardTitle {
  // font-weight: 600;
  opacity: 50%;
  font-size: 12px;
  text-align: start;
}

.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h3{
  
}
@media (min-width: 360px) and (max-width: 429px) {
  .icon{
    width: 8px;
    height: 8px;
  }
  .icon2{
    width: 10px;
    height: 10px;
  }
  .leftTd{
    opacity: 0.5;
    text-align: left;
    
    font-size: 8px;
    padding: 5px;
    padding-right: 0px;
  }
  
  .rightTd{
    opacity: 0.5;
    text-align: right;
    
    font-size: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 5px;
    width: 50%;
  }
  
  .rows{
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
  }
  
  .bottomRow{
    border-style: none;
  }
  
  .table{
    width: 100%
  }





//Style for ShowInstructions
.headerTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 0;
    color: #5271ff;
    
    font-size: 14px;
  }
  .headerSubtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #979797;
    
    font-size: 10px
  }

  .passCode{
    margin-top: 10px;
    
    font-weight: 800;
    color: #4C4D52;
    font-size: 14px;
  }
  .leftTdShow{
    opacity: 0.5;
    text-align: left;
    
    font-size: 10px;
    padding: 15px;
    padding-right: 50px;
    padding-left: 0px;
  }
  
  .rightTdShow{
    opacity: 0.5;
    text-align: right;
    
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 45px;
    padding-right: 0px;
  }
  .rightTdShowInst{
    opacity: 0.5;
    text-align: right;
    
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50px;
    display: block;
  }

  .buttons {
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
    
  }
  
  .button {
    display: flex;
    gap: 12px;
    justify-content: center;
    width: 100%;
    font-size: 12px !important;
  }
  .container {
    max-width: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
  }

  .timeShow{
    
    font-size: 10px;
    font-weight: 500;
  }

  .circle {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
    line-height: 31px;
    width: 45px;
    aspect-ratio: 1;
    border-radius: 100px;
    font-weight: 700;
    background-color: #5271ff;
    position: relative;
    bottom: 50px; 
    left: 15px; 
}

.infoRow {
    
    font-weight: 600;
    font-size: 8px;
    width: auto;
    text-transform: uppercase;
    text-align: center;
    color: #4C4D52;
}

.circleFrame {
    width: 200px; 
    height: 200px; 
    border-radius: 50%; 
    overflow: hidden; 
    border: 3px solid #5271ff; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
}

.circleFrame img {
    width: 100%; 
    height: 100%; 
    display: block; 
    object-fit: cover;
}
.smallCircleFrame{
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  overflow: hidden; 
  border: 2px solid #5271ff; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 65%; 
  left: 60%; 
  transform: translate(-50%, -50%);
}
.smallCircleFrame img {
  width: 100%; 
  height: 100%; 
  display: block; 
  object-fit: cover;
}
.smallLeftFrame{
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  overflow: hidden; 
  border: 2px solid #5271ff; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 65%; 
  left: 40%; 
  transform: translate(-50%, -50%);
}
.smallLeftFrame img {
  width: 100%; 
  height: 100%; 
  display: block; 
  object-fit: cover;
}
.stepGuide {
  max-width: 150px; 
  text-align: center; 
  margin: 5px auto; 
  
  font-weight: 400;
  font-size: 14px;
}

.helpInfo{
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 8px;
  color: rgb(0, 0, 0, 0.6);
  
  text-align: center;
}
.helpInfo img{
  height: 8px;
  width: 8px;
  margin-right: 5px;
}
.backArrow:hover{
  cursor: pointer;
}

.moreInfo{
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  color: rgb(0, 0, 0, 0.6);
  
}

.asText {
  background:none;
  border:none;
  margin:0;
  padding:0;
  
  font-size: 8px;
  text-decoration: underline;
  color: #5271ff;
  text-transform: uppercase;
}

.leftTdShowCode{
  opacity: 0.5;
  text-align: left;
  
  font-size: 12px;
  padding: 15px;
  padding-right: 50px;
}

.rightTdShowCode{
  opacity: 0.5;
  text-align: right;
  
  font-size: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-right: 15px;
}

.imgContainer{
  display: flex;
  gap: 5px;
}

.imgSize{
  width: 25%;
  border-radius: 20px;
  padding: 4px;
}
.stepContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.customModal {
  display: flex;
  align-items: center;
  justify-content: center;  
  width: 80%; 
  height: 100%; 
  max-height: 100%; 
  padding-left: 50px;
}

.customModal .modal-body {
  overflow-y: auto; 
}
h3{
  font-size: 15px;
}
}

@media (min-width: 430px) and (max-width: 767px) {
  .icon{
    width: 8px;
    height: 8px;
  }
  .icon2{
    width: 10px;
    height: 10px;
  }
  .leftTd{
    opacity: 0.5;
    text-align: left;
    
    font-size: 8px;
    padding: 5px;
    padding-right: 0px;
  }
  
  .rightTd{
    opacity: 0.5;
    text-align: right;
    
    font-size: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 5px;
    width: 50%;
  }
  
  .rows{
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
  }
  
  .bottomRow{
    border-style: none;
  }
  
  .table{
    width: 100%
  }





//Style for ShowInstructions
.headerTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 0;
    color: #5271ff;
    
    font-size: 14px;
  }
  .headerSubtitle{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #979797;
    
    font-size: 10px
  }

  .passCode{
    margin-top: 10px;
    
    font-weight: 800;
    color: #4C4D52;
    font-size: 14px;
  }
  .leftTdShow{
    opacity: 0.5;
    text-align: left;
    
    font-size: 10px;
    padding: 15px;
    padding-right: 50px;
    padding-left: 0px;
  }
  
  .rightTdShow{
    opacity: 0.5;
    text-align: right;
    
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 45px;
    padding-right: 0px;
  }
  .rightTdShowInst{
    opacity: 0.5;
    text-align: right;
    
    font-size: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 50px;
    padding-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50px;
    display: block;
  }

  .buttons {
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;
    
  }
  
  .button {
    display: flex;
    gap: 12px;
    justify-content: center;
    width: 100%;
    font-size: 12px !important;
  }
  .container {
    max-width: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
  }

  .timeShow{
    
    font-size: 10px;
    font-weight: 500;
  }

  .circle {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 18px;
    line-height: 31px;
    width: 45px;
    aspect-ratio: 1;
    border-radius: 100px;
    font-weight: 700;
    background-color: #5271ff;
    position: relative;
    bottom: 50px; 
    left: 15px; 
}

.infoRow {
    
    font-weight: 600;
    font-size: 8px;
    width: auto;
    text-transform: uppercase;
    text-align: center;
    color: #4C4D52;
}

.circleFrame {
    width: 200px; 
    height: 200px; 
    border-radius: 50%; 
    overflow: hidden; 
    border: 3px solid #5271ff; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
}

.circleFrame img {
    width: 100%; 
    height: 100%; 
    display: block; 
    object-fit: cover;
}

.smallCircleFrame{
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  overflow: hidden; 
  border: 2px solid #5271ff; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 65%; 
  left: 60%; 
  transform: translate(-50%, -50%);
}
.smallCircleFrame img {
  width: 100%; 
  height: 100%; 
  display: block; 
  object-fit: cover;
}
.smallLeftFrame{
  width: 40px; 
  height: 40px; 
  border-radius: 50%; 
  overflow: hidden; 
  border: 2px solid #5271ff; 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: 65%; 
  left: 40%; 
  transform: translate(-50%, -50%);
}
.smallLeftFrame img {
  width: 100%; 
  height: 100%; 
  display: block; 
  object-fit: cover;
}

.stepGuide {
  max-width: 150px; 
  text-align: center; 
  margin: 5px auto; 
  
  font-weight: 400;
  font-size: 14px;
}

.helpInfo{
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 8px;
  color: rgb(0, 0, 0, 0.6);
  
  text-align: center;
}
.helpInfo img{
  height: 8px;
  width: 8px;
  margin-right: 5px;
}
.backArrow:hover{
  cursor: pointer;
}

.moreInfo{
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  color: rgb(0, 0, 0, 0.6);
  
}

.asText {
  background:none;
  border:none;
  margin:0;
  padding:0;
  
  font-size: 8px;
  text-decoration: underline;
  color: #5271ff;
  text-transform: uppercase;
}

.leftTdShowCode{
  opacity: 0.5;
  text-align: left;
  
  font-size: 12px;
  padding: 15px;
  padding-right: 50px;
}

.rightTdShowCode{
  opacity: 0.5;
  text-align: right;
  
  font-size: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  padding-right: 15px;
}

.imgContainer{
  display: flex;
  gap: 5px;
}

.imgSize{
  width: 25%;
  border-radius: 20px;
  padding: 4px;
}
.stepContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.customModal {
  display: flex;
  align-items: center;
  justify-content: center;  
  width: 80%; 
  height: 100%; 
  max-height: 100%; 
  padding-left: 50px;
}

.customModal .modal-body {
  overflow-y: auto; 
}
h3{
  font-size: 12px;
}
}

