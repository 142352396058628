.fc-header-toolbar {
    .fc-toolbar-chunk {
        padding: 0 10px;
    }
    .fc-toolbar-title{
        color: #5f5f5f;
        display: none;
    }
}
.fc-button-group {
    .fc-button {
        background-color: transparent;
        color: #929399;
        padding: 4px 7px;
        margin: 1px;
        border: #929399 solid 1px;
        text-transform: capitalize;
        border-radius: 3px;

        &:focus {
            box-shadow: none;
        }

        &.fc-button-active {
            background-color: #EEEEEE;
            color: #5271FF;
            font-weight: 600;
            border: #5271FF solid 2px;
        }

        &.fc-prev-button {
            margin-left: 10px;
        }
        &:hover{
            border-color: #5271FF;
            color: #5271FF;
        }

    }
    
    .fc-today-button:disabled:hover {
        border-color: #929399; 
        color: #929399; 
        cursor: not-allowed; 
    }

}
.fc .fc-daygrid-event-dot {
    border: calc(var(--fc-daygrid-event-dot-width) / 3) solid;
    border-radius: calc(var(--fc-daygrid-event-dot-width) / 2);
    box-sizing: content-box;
    height: 0px;
    margin: 0px 2px;
    width: 0px;
}
.fc .fc-popover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px;
    position: absolute;
    z-index: 9999;
}
.fc-event-bg-1{
    background: #ccf1ce;
    border: none;
}
.fc-event-bg-2{
    background: #cbf4e4;
    border: none;
}
.fc-event-bg-3{
    background: #c0f5f8;
    border: none;
}
.fc-event-bg-4{
    background: #dce3ff;
    border: none;
}
.fc-event-bg-5{
    background: #e3cdff;
    border: none;
}
.fc-event-bg-6{
    background: #f3bfdd;
    border: none;
}
.fc-event-bg-7{
    background: #ffcce5;
    border: none;
}
.fc-event-bg-8{
    background: #ffc2c3;
    border: none;
}
.fc-event-bg-9{
    background: #fcd293;
    border: none;
}
.fc-event-bg-0{
    background: #fffacc;
    border: none;
}
.fc-event-bg-default{
    background: #CBCBCB;
    border: none;
}
.fc .fc-daygrid-event{
    // background: rgba(82, 113, 255, 0.5);
    font-size: 10px;
}
.fc .fc-daygrid-more-link{
    margin-top: 5px;
    padding: 10px;
    font-weight: 500;
    font-size: 15px;
}
.fc {
    .fc-event {
        .fc-event-main {
            .fc-event-main-frame {
                .fc-event-time {
                    display: none;
                }
            }
        }
    }
    @media (max-width:755px) {
        .fc-popover {
            width: 380px;
        }
        .fc-daygrid-day-events{
            top: 25px;
        }
        .fc-daygrid-more-link{
            font-size: 10px;
            padding: 8px;
            padding-left: 0;
        }
        // .fc-toolbar{
        //     display: flex;
        //     flex-direction: column;
        // }
        .fc-daygrid-day-number{
            font-size: 9px;
        }
        .fc-daygrid-event{
            font-size: 9px;
        }
    }

    .fc-popover {
        z-index: 999;
    }

    .fc-col-header-cell-cushion {
        font-size: 15px;
        line-height: 25px;
        color: #2D2F36;
        text-decoration: none;
    }

    

    .fc-daygrid-day.fc-day-today {
        background-color: #F8F9FB;
    }

    .fc-timegrid-col {
        &.fc-day-today {
            background-color: #F8F9FB;
        }
    }

    a,
    .fc-daygrid-day-number {
        line-height: 25px;
        color: #2D2F36;
        text-decoration: none;
    }

    .fc-daygrid-day-top {
        flex-direction: row;
    }
}

.custom-dropdown {
    background-color: rgba(146, 147, 153, 0.30);
    font-size: 11px;
    letter-spacing: 0.8px;
    line-height: 15px;
    color: #2D2F36;
    border-radius: 3px;
    padding: 2px 28px 2px 7px;
    display: inline-block;
    text-transform: uppercase;
    background-image: url('../../assests/images/downarrow.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    cursor: pointer;

    &:after {
        content: "";
        height: 10px;
        position: absolute;
        bottom: -8px;
        width: 100%;
        left: 0;

    }

    ul {
        min-width: 190px;
        background-color: #fff;
        border-radius: 3px;
        padding: 8px;
        position: absolute;
        right: 0;
        top: 28px;
        margin-bottom: 0;
        display: none;
        padding-left: 10px;
        list-style: none;
        z-index: 1;
        box-shadow: 0 7px 12px rgba(0, 0, 0, 0.08);

        li {
            text-transform: uppercase;
            font-size: 11px;
            letter-spacing: 0.8px;
            line-height: 15px;
            color: #2D2F36;
            margin-bottom: 10px;
            cursor: pointer;
            text-align: left;
            display: flex;

            &.w-square {
                padding-left: 36px;

                &:before {
                    content: "";
                    height: 20px;
                    width: 20px;
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: -35px;
                    margin-right: 8px;
                    border-radius: 3px;
                }

                &.interested {
                    &:before {
                        background-color: #52FF58;
                        opacity: 0.30;
                    }
                }

                &.no-interest {
                    &:before {
                        background-color: #FF7277;
                        opacity: 0.30;
                    }
                }

                &.never-arrived {
                    &:before {
                        background-color: #E9D62A;
                        opacity: 0.30;
                    }
                }

                &.sent-application {
                    &:before {
                        background-color: #5271FF;
                        opacity: 0.30;
                    }
                }

                &.rescheduled {
                    &:before {
                        background-color: #929399;
                        opacity: 0.30;
                    }
                }

                &.leave-this {
                    &:before {
                        background-color: rgba(146, 147, 153, 0.30);
                        content: "x";
                        color: #858588;
                        text-align: center;
                        line-height: 20px;
                    }
                }
            }

            button {
                background-color: rgba(82, 113, 255, 0.3);
                font-size: 11px;
                letter-spacing: 0.8px;
                line-height: 15px;
                border-radius: 3px;
                border: none;
                text-transform: uppercase;
                color: #2D2F36;
                width: 100%;
                text-align: center;

            }
        }
    }

    &:hover {
        ul {
            display: block;
        }
    }
}

.offcanvas {
    &.showing-offcanvas {
        max-width: 400px;
        width: 100%;

        .offcanvas-body {
            padding: 20px 30px;

            .offcanvas-panel {
                em {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.35px;
                }

                .link-w-ico {
                    border-top: 1px solid #E8E8E8;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    &.mail-w-ico {
                        border-bottom: 1px solid #E8E8E8;
                    }
                }
            }
        }
    }
}

.removeShowing-modal {
    .modal-header {
        .btn-close {
            margin-top: -120px;
        }
    }

}

.tabs {
    display: flex;

    .tab {
        width: 100%;
        border: none;
        font-size: 14px;
        line-height: 20px;
        border-radius: 3px;
        background-color: #FAFAFA;
        padding: 4px 7px;
        color: #1F2327;
        display: flex;
        align-items: center;

        img {
            margin-right: 7px;
        }
    }

    .tab:not(:last-child) {}

    .tab.active {
        background-color: #EEEEEE;
    }


}



.panel {
    display: none;


    &.active {
        display: block;
    }
}

.showings-leads-wrapper {
    margin: 0;
    margin-left: -30px;

}

.offcanvas {
    &.reschedule-offcanvas {
        max-width: 484px;

        .offcanvas-header {
            background-color: #F6F6F6;

            .offcanvas-title {
                color: #1F2327;
            }
        }

        .offcanvas-calander-wrapper {
            overflow: hidden;
            margin-left: -30px;
            margin-right: -30px;

            .react-datepicker {
                .react-datepicker__day {
                    margin: 0;
                }

                .react-datepicker__time-container {
                    width: 100px;
                    margin-left: 17px;
                }
            }
        }
    }
}

.select-property-panel {
    border-radius: 5px;
    background-color: #FAFAFA;
    border: 1px solid #DCDCDC;
    padding: 28px 15px;
}

.panel-disabled {
    opacity: 0.3;
}

.showing-calander-wrapper {
    .date-picker-wrapper {
        max-width: 55%;
        flex: 0 0 55%;

        @media(max-width:767px) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .selected-day-wrapper {
        max-width: 45%;
        flex: 0 0 45%;

        @media(max-width:767px) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        &.before-selecting {
            background-color: #ECECEC;
        }
    }

    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
        display: block;
    }

    .react-datepicker {


        .react-datepicker__navigation--previous {
            left: auto;
            right: 30px;
            background-image: url('../../assests/images/date-left-arrow.png');
            background-repeat: no-repeat;
            background-position: center;

            @media (max-width: 767px) {
                right: 35px;
            }



        }

        .react-datepicker__navigation--next {
            right: 5px;
            background-image: url('../../assests/images/date-right-arrow.png');
            background-repeat: no-repeat;
            background-position: center;
        }

        .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
            right: 15px;
            background-image: url('../../assests/images/date-right-arrow.png');
            background-repeat: no-repeat;
            background-position: center;

            @media (max-width: 767px) {
                right: 5px;
            }

        }
    }

}

.selectproperty-offcanvas {
    &.newshowing-offcanvas {
        max-width: 825px;
        width: 100%;

        .offcanvas-body {
            padding: 0;

            .page-main-head {
                @media(max-width:767px) {
                    margin-bottom: 10px;
                }

                .search-control-group {
                    margin: 0;
                    padding: 15px 45px 15px 20px;

                    .form-control {
                        padding: 10px 15px;
                        border-color: #DCDCDC;
                        height: 40px;

                    }

                    .btn {
                        width: 40px;
                        height: 40px;
                        margin-top: -20px;
                        right: 46px;
                        background-color: transparent;
                    }
                }

                .back-btn {
                    width: 75px;
                    height: 70px;
                    background: #DFDFDF url('../../assests/images/offcanvas-back.png') center center no-repeat;
                    padding: 0;
                    margin: 0;
                    border: none;
                    border-radius: 0;

                    &:hover {
                        background: #fff url('../../assests/images/offcanvas-back-hover.png') center center no-repeat;
                    }
                }

                .btn.date-btn {

                    width: 119px;
                    padding: 4px 10px 4px 20px;
                    min-width: auto;
                    background-position: 3px center;
                    background-color: #fff;
                    border-color: #fff;

                    .react-dropdown-select-content {
                        flex-wrap: nowrap;

                        span {
                            text-overflow: ellipsis;
                            max-width: 80px;
                            display: inline-block;
                            overflow: hidden;
                        }
                    }

                    .react-dropdown-select-dropdown-handle {
                        position: absolute;
                        right: 0;
                    }

                }
            }

            .property-table {
                tr {
                    td {
                        .addreess {
                            font-size: 18px;
                            line-height: 25px;
                        }

                        .eminity-group {
                            p {
                                margin-left: 20px;

                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                        }

                        .f-s-17 {
                            font-size: 17px;
                        }

                        .eminity-group {

                            .apartment,
                            .bedroom,
                            .bath {
                                font-size: 11px;
                                line-height: 15px;
                                letter-spacing: 0.8px;
                            }

                            .line-height-0 {
                                line-height: 0;
                            }
                        }
                    }
                }
            }

        }
    }

}

.fc-timegrid-event {
    margin-bottom: -11px;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
    box-shadow: none;
}

.fc-daygrid-dot-event {
    padding: 0;
}
.fc-timegrid-event .fc-event-main {
    padding: 0;
}
.fc .fc-timegrid-event, .fc-daygrid-event {
    cursor: pointer;
}