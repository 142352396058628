#scroll::-webkit-scrollbar-track {
  // border-radius: 10px;
  // background-color: #f5f5f5;
}

#scroll::-webkit-scrollbar {
  width: 0px;
  display: none;
  // background-color: #f5f5f5;
}

#scroll::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  // background-color: #c4c9d2;
  // height: 5px;
}

.page-main-head-book {
  // background-image: url("../../assests/images/book-bg.png") !important;
  background-image: linear-gradient(135deg, #fafdff 0%, #babcf1 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  overflow-y: scroll;

  .book-head-logo {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;

    img {
      // height: auto;
      max-width: 170px;
      max-height: 50px;
    }
  }

  @media only screen and (max-width: 768px) {
    .book-head-logo {
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px;

    .container {
      position: relative;
      z-index: 0;
    }

    .disabled {
      position: absolute;
      top: 0;
      height: 570px;
      width: 325px;
      background-color: rgba(0, 0, 0, 25%);
      border-radius: 21px;
      pointer-events: none;
      z-index: 5;
      margin-top: 10px;
    }

    .steps-container {
      height: 100%;
      width: 325px;
      // overflow-y: scroll;
      z-index: 0;
      // padding-bottom: 50px;
      margin-bottom: 30px;
    }

    .step1 {
      padding-right: 3px;
    }
  }

  .step-point {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .step-content {
    padding: 15px;
    border: 1px solid #007bff;
    border-radius: 5px;
  }

  .step-line {
    width: 100%;
    height: 2px;
    background-color: #007bff;
    position: absolute;
    top: 14px;
    z-index: -1;
  }

  .step-container {
    // overflow-y: auto;
    // max-height: calc(100vh - 150px);
  }

  .steps .step {
    display: block;
    margin-bottom: 15px;
    text-align: center;
  }

  .steps .step .step-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 30px;
    text-align: center;
  }

  .steps .step .step-icon-wrap::before,
  .steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 2px;
    margin-top: -1px;
    background-color: #000000;
    content: '';
    z-index: 1;
  }

  .steps .step .step-icon-wrap::before {
    left: 0;
  }

  .steps .step .step-icon-wrap::after {
    right: 0;
  }

  .steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    border: 1px solid #00000096;
    border-radius: 50%;
    background-color: #ffffff;
    color: #374250;
    font-size: 38px;
    line-height: 81px;
    z-index: 5;
  }

  .Vector-icon {
    position: absolute;
    top: 32%;
    left: 28%;
    color: white;
    border: none;
  }

  .step-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #000;
  }

  .steps .step .step-title {
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
  }

  .steps .step:first-child .step-icon-wrap::before {
    display: none;
  }

  .steps .step:last-child .step-icon-wrap::after {
    display: none;
  }

  .steps .step.completed .step-icon-wrap::before,
  .steps .step.completed .step-icon-wrap::after {
    background-color: #4067f9;
  }

  .steps .step.beforecompleted .step-icon-wrap::before {
    background-color: #4067f9;
  }

  .steps .step.completed .step-icon {
    border-color: #fff;
    background-color: #4067f9;
    color: #fff;
  }

  .card {
    border-radius: 21px;
    margin-bottom: 10px !important;
    height: auto !important;

    .card-body {
      padding: 15px 28px 8px 28px !important;
      cursor: pointer;
    }

    p {
      font-weight: bold;
      font-size: 10px;
      margin: 0;
    }

    .item-quentity {
      font-size: 10px;
      font-weight: bold;
    }

    .price {
      font-size: 16px;
      color: #007bff;
      font-weight: bold;
    }

    .slick-list {
      border-radius: 5px !important;
    }

    .slick-slide img {
      max-height: 130px;
    }

    .slick-prev,
    .slick-next {
      display: block !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 11;
      cursor: pointer;
    }

    .slick-prev {
      // left: 0;
    }

    .slick-next {
      // right: 0;
    }

    .slick-prev {
      content: url('../../assests/images/PrevArrow.svg');
      opacity: 1;
    }

    .slick-next {
      content: url('../../assests/images/NextArrow.svg');
      opacity: 1;
    }
  }

  .calendar-wrapper {
    display: flex;
    justify-content: center;

    .date-picker-wrapper {
      width: 290px;

      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }

      .react-datepicker {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        background-color: transparent;
        border: none;

        .react-datepicker__navigation--next--with-time:not(
            .react-datepicker__navigation--next--with-today-button
          ) {
          right: 12px;
          top: 15px;
        }

        .react-datepicker__navigation--previous {
          left: 12px;
          top: 15px;
        }

        .react-datepicker__month-container {
          background-color: #ffffff;
          border-radius: 28px;
          width: 100%;

          .react-datepicker__day-names {
            font-size: 14px;
          }

          .react-datepicker__header {
            border-top-left-radius: 21px;
            border-top-right-radius: 21px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: none;
            background-color: white;
            padding-bottom: 0px !important;

            .react-datepicker__current-month {
              color: #fff;
              background: black;
              border-radius: 28px;
              width: 90%;
              padding: 3px 0px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .react-datepicker__time-container {
          background-color: #ffffff;
          border-radius: 28px;
          height: 260px;
          width: 100%;
          padding: 20px 10px;
          margin-top: 20px;
          border: none;

          .react-datepicker__header--time {
            display: none;
          }

          .react-datepicker__time {
            border: none;
            width: 100%;
            height: 100%;

            .react-datepicker__time-box {
              width: 90%;
              height: 100%;
              overflow: hidden;

              ul.react-datepicker__time-list {
                li.react-datepicker__time-list-item {
                  background-color: #dde5eb !important;
                  margin: 10px 0px;
                  border-radius: 30px;
                  font-size: 12px;
                  font-weight: bold;
                  height: 34px;
                  transition: background-color 0.3s ease, color 0.3s ease;
                }

                li.react-datepicker__time-list-item:hover {
                  color: #fff !important;
                  background-color: black !important;
                  cursor: pointer;
                }

                li.react-datepicker__time-list-item--selected {
                  background-color: black !important;
                }

                li.react-datepicker__time-list-item--disabled {
                  display: none;
                }
              }
            }
          }
        }

        ul.react-datepicker__time-list::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .input-wrapper {
    margin-bottom: 18px;

    input {
      font-size: 14px;
      font-weight: 300;
      padding: 8px 15px;

      border: none;
      border-radius: 11px;
    }

    input::placeholder {
      color: #999;
    }
  }

  .formFile-control {
    width: 100%;
    height: 246px;
    background-color: #ffffff;
    border-radius: 21px;
    padding: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .img-drop-area {
      height: 155px;
      width: 100%;
      background-color: white;
      background-image: url('/assests/images/gallery-icon.png');

      img {
        background-color: white;
      }
    }

    .btn {
      padding: 5px 40px !important;
    }
  }

  .btn-lg {
    padding: 25px;
    width: 100%;
    color: #ffffff;
    background-color: #021627;
    font-weight: 700;
    font-size: 20px;
    border-radius: 21px;
    margin-top: 38px;
  }

  .btn-md {
    padding: 15px;
    width: 90%;
    color: #ffffff;
    background-color: #021627;
    font-weight: 700;
    font-size: 20px;
    border-radius: 21px;
    margin-top: 25px;
  }
}

.react-datepicker__day:not(.react-datepicker__day--disabled):hover {
  background-color: #4067f9;
  border-radius: 1.3rem;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #4067f9;
  border-radius: 1.3rem;
  color: white;
}

.react-datepicker__day--selected {
  background-color: #4067f9;
  border-radius: 1.3rem;
}

.slick-arrow {
  &::before {
    color: #4067f9 !important;
  }
}

.overlay-div {
  height: 620px;
  opacity: 0.5;
  border-radius: 28px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 29%);
  // position: relative;
}

.step-hide {
  display: none;
}

.offcanvas-panel {
  em {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.35px;
  }

  .link-w-ico {
    border-top: 1px solid #e8e8e8;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    &.mail-w-ico {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
