.input{
    font-size: 13px;
    color: #adb5bd
}
.input:focus{
    outline: none;
    border: 1px solid #5271ff !important;
}

.root:focus{
    outline: none;
    border: 1px solid red;
}

