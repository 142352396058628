.card {
  // padding: 50px;
  width: 75% ;
  aspect-ratio: 1;
  box-shadow: 0px 5px 0.625rem 0px rgba(0, 0, 0, 0.15) !important;
  
  // position: relative;
}
.container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.label {
  display: grid;
  gap: 0.625rem;
  cursor: pointer;
  padding: 0.625rem;
}

.card .img {
  aspect-ratio: 3/2;
  object-fit: cover;
}

.card .title,
.card .subtitle {
  overflow: hidden;
  display: -webkit-box;
  
  -webkit-box-orient: vertical;
}
.card .title {
  line-height: 1.875rem;
  min-height: 1.875rem;
  -webkit-line-clamp: 1;
  
  line-clamp: 1;
}
.card .subtitle {
  line-height: 1.5rem;
  min-height: 3rem;
  -webkit-line-clamp: 2;
  
  line-clamp: 2;
}
.title{
  font-size: 1.25rem;
  color:#979797;
}
.subtitle{
  font-size: 1.15rem;
}

.container {
  padding-inline: 0.625rem;
}
// @media (max-width: 767px) {
//   .container {
//     padding-inline: 6px;
//   }
//   .card {
//     padding: 12px;
//     border-radius: 14px;
//     font-size: 13px;
//   }
//   .card .img {
//     border-radius: 6px;
//   }
// }

.card input[type='radio'] {
  visibility: hidden;
  position: absolute;
  inset: 0;
}

input[type='checkbox'] {
  accent-color:  #5271ff;
}

.text-delet {
  accent-color: #5271ff;
  color: #5271ff;
  background-color: #5271ff;
}

.tableCard{
  width: 100%;
  border-bottom: solid;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}
.row{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.tableData{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.checkInput{
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  height: 1.125rem;
  width: 1.125rem;
}

.iconButton{
  border: none;
  background-color: transparent;
  height: 25px;
  margin-right: 10px;
  margin-left: 10px;
}
.icon{
  color: #979797;
  height: 25px;
  width: 25px;
}

.clickedLabel{
  display: flex;
  align-items: end;
  color: #5271ff;
}
.normalLabel{
  color: #979797;
  
  display: flex;
  align-items: end;
}
.propImage{
  border-radius: 50px;
  width: 100%;
  height: 85%;
  padding: 20px;
}

@media (min-width: 360px) and (max-width: 429px){
  
  .card {
    // padding: 50px;
    width: 100% ;
    aspect-ratio: 1;
    box-shadow: 0px 5px 0.625rem 0px rgba(0, 0, 0, 0.15) !important;
    
    // position: relative;
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
   
  }
  .title{
    font-size: 0.825rem;
    padding-left: 0;
    
  }
  .subtitle{
    padding-left: 0;
    font-size: 0.825rem;
  }
  .label {
    display: grid;
    gap: 0;
    cursor: pointer;
    padding: 0;
    font-size: 0.625rem;
  }
  
  .card .img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  
  .card .title,
  .card .subtitle {
    overflow: hidden;
    display: -webkit-box;
    
    -webkit-box-orient: vertical;
  }
  .card .title {
    line-height: 1rem;
    min-height: 1rem;
    -webkit-line-clamp: 1;
    
    line-clamp: 1;
  }
  .card .subtitle {
    line-height: 1rem;
    min-height: 1rem;
    -webkit-line-clamp: 2;
    
    line-clamp: 2;
  }
  .tableData{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .checkInput{
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    height:  0.825rem;
    width:  0.825rem;
  }
  .iconButton{
    border: none;
    background-color: transparent;
    height: 21px;
    margin-right: 1px;
    margin-left: 1px;

  }
  .icon{
    color: #979797;
    height: 18px;
    width: 18px;
  }
  .clickedLabel{
    display: flex;
    align-items: end;
    color: #5271ff;
    
    font-size: 0.825rem;
  }
  .normalLabel{
    color: #979797;
    
    display: flex;
    align-items: end;
    font-size: 0.825rem;
  }
  .propImage{
    border-radius: 20px;
    width: 100%;
    height: 80%;
    padding: 5px;
  }
}

@media (min-width: 430px) and (max-width: 767px){
  
  .card {
    // padding: 50px;
    width: 100% ;
    aspect-ratio: 1;
    box-shadow: 0px 5px 0.625rem 0px rgba(0, 0, 0, 0.15) !important;
    
    // position: relative;
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .title{
    font-size: 1rem;
    padding-left: 0;
    
  }
  .subtitle{
    padding-left: 0;
    font-size: 1rem;
  }
  .label {
    display: grid;
    gap: 0;
    cursor: pointer;
    padding: 0;
    font-size: 0.75rem;
  }
  
  .card .img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  
  .card .title,
  .card .subtitle {
    overflow: hidden;
    display: -webkit-box;
    
    -webkit-box-orient: vertical;
  }
  .card .title {
    line-height: 1rem;
    min-height: 1rem;
    -webkit-line-clamp: 1;
    
    line-clamp: 1;
  }
  .card .subtitle {
    line-height: 0.785rem;
    min-height: 1rem;
    -webkit-line-clamp: 2;
    
    line-clamp: 2;
  }
  .tableData{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .checkInput{
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    height:  1rem;
    width:  1rem;
  }
  .iconButton{
    border: none;
    background-color: transparent;
    height: 25px;
    margin-right: 1px;
    margin-left: 1px;

  }
  .icon{
    color: #979797;
    height: 25px;
    width: 25px;
  }
  .clickedLabel{
    display: flex;
    align-items: end;
    color: #5271ff;
    
    font-size: 1rem;
  }
  .normalLabel{
    color: #979797;
    
    display: flex;
    align-items: end;
    font-size: 1rem;
  }
  .propImage{
    border-radius: 20px;
    width: 100%;
    height: 85%;
    padding: 5px;
  }
}
@media (min-width: 768px) and (max-width: 2000px){
  
  .card {
    margin: 50px;
    width: 100% ;
    aspect-ratio: 1;
    box-shadow: 0px 5px 0.625rem 0px rgba(0, 0, 0, 0.15) !important;
    
    // position: relative;
  }
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .title{
    font-size: 1rem;
    padding-left: 0;
    
  }
  .subtitle{
    padding-left: 0;
    font-size: 1rem;
  }
  .label {
    display: grid;
    gap: 0;
    cursor: pointer;
    padding: 0;
    font-size: 0.625rem;
  }
  
  .card .img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  
  .card .title,
  .card .subtitle {
    overflow: hidden;
    display: -webkit-box;
    
    -webkit-box-orient: vertical;
  }
  .card .title {
    line-height: 1rem;
    min-height: 1rem;
    -webkit-line-clamp: 1;
    
    line-clamp: 1;
  }
  .card .subtitle {
    line-height: 1.8rem;
    min-height: 1rem;
    -webkit-line-clamp: 2;
    
    line-clamp: 2;
  }
  .tableData{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .checkInput{
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    height:  1rem;
    width:  1rem;
  }
  .iconButton{
    border: none;
    background-color: transparent;
    height: 25px;
    margin-right: 1px;
    margin-left: 1px;

  }
  .icon{
    color: #979797;
    height: 25px;
    width: 25px;
  }
  .clickedLabel{
    display: flex;
    align-items: end;
    color: #5271ff;
    
    font-size: 1rem;
  }
  .normalLabel{
    color: #979797;
    
    display: flex;
    align-items: end;
    font-size: 1rem;
  }
  .propImage{
    border-radius: 20px;
    width: 100%;
    height: 85%;
    padding: 5px;
  }
}

@media (max-width: 389px){
  .propImage{
    border-radius: 20px;
    width: 100%;
    height: 85%;
    padding: 5px;
  }
}