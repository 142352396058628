
.card {
    display: grid;
    // gap: 12px;
    margin-top: 0px;
    margin-bottom: 25px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 0px;
    // box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #e0e0e0;
  }
  .orderCard {
    display: grid;
    // gap: 12px;
    margin-top: 0px;
    margin-bottom: 25px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 0px;
   box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
   border: 1px solid #e0e0e0;
  }
  
  .button {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: 100px;
    margin-right: 100px;
  }

  .inputContainer {
    position: relative;
    width: 95%;
    margin: 0px 20px 20px 30px;
  }
  .inputContainerLoc {
    position: relative;
    width: 100%;
    opacity: 0.5;
  }
 
  .leftTd{
    text-align: left;
    
    font-size: 12px;
    padding: 15px;
    color: #4C4D52;
    font-weight: 600;
  }
  .leftTdTot{
    text-align: left;
    
    font-size: 12px;
    padding: 15px;
    // color: #4C4D52;
    font-weight: 800;
  }

  .rightTd{
    text-align: right;
    
    font-size: 12px;
    padding: 10px;
    color: #4C4D52;
    font-weight: 400;
  }
  .midTd{
    
    font-size: 12px;
    padding: 10px;
    color: #4C4D52;
    font-weight: 400;
  }
  .rightTdTot{
    text-align: right;
    
    font-size: 12px;
    padding: 10px;
    font-weight: 800;
  }

  .rows{
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
  }

  .bottomRow{
    border-style: none;
  }
  
.formHeader{
    display: flex;
    // justify-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: #EAEAEB;
    height: 50px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    font-size: 14px;
    
    font-weight: 600;
    margin-bottom: 20px;
}

.headerRow{
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #979797;
    
    font-weight: 600;
    margin-right: 15px;
    margin-left: 15px;
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
}

  .closeIcon{
    position: absolute;
    top: 33px;
    left: 97.5%;
    opacity: 0.5;
  }

  .closeIcon:hover{
    cursor: pointer;
  }

  .pageHeader{
    
    font-weight: 600;
    font-size: '22.5px';
    color: #4C4D52;
    margin: 0 0 20px 50px;
  }