.card {
  text-align: start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.infoGroup {
  display: flex;
  gap: 1rem;
}

.img {
  width: 25%;
  object-fit: cover;
  border-radius: 8px;
}

.link {
  margin-left: auto;
}

@media (max-width: 750px) {
  .card {
    padding: 8px;
  }
}

@media (min-width: 751px) {
  .card {
    width: 550px;
    max-width: 80%;
    margin-inline: auto;
  }
  .link {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
