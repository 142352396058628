.button {
  font-weight: 700;
  
}

a.button {
  text-decoration: none;
  text-align: center;
}

.button.medium {
  line-height: 24px;
  padding: 8px;
  border-radius: 6px;
}

.button.large {
  line-height: 30px;
  padding: 16px;
  border-radius: 11px;
}

.button.primary {
  color: #ffffff;
  border: none;
  background-color: #5271ff;
  &:hover {
    background-color: #0e3ed9;
  }
}

.button.secondary {
  border: 1px solid #979797b2;
  background-color: transparent;
  color: #000000;
  &:hover {
    background-color: #f0f2ff;
  }
}

.button.disabled {
  color: #00000033;
  background-color: #dee2e6;
  pointer-events: none;
}

button.text,
button.link {
  font-weight: 600;
  background-color: transparent;
  border: none;
}

.button.link {
  color: #5271ff;
  &:hover {
    color: #0e3ed9;
  }
  &.disable {
    color: #c8cbcf;
  }
}

.button.text {
  color: #000000;
  &:hover {
    color: #5271ff;
  }
}
