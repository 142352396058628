.container {
  display: grid;
  gap: 15px;
  place-content: center;
}
.container > * {
  max-width: 600px;
}

.modal-content {
  width: fit-content !important;
}

.close-btn {
  top: 5px !important;
  right: 5px !important;
}

@media (max-width: 755px) {
  .card {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.input {
  border: 1px solid rgb(153, 153, 153, 0.5);
  padding: 10px;
  font-size: 12px;
  border-radius: 6px;
  width: 100%;
  text-align: right;
  font-size: 12px;

  padding-left: 100px;
}
.input:focus {
  outline: none;
  border: 2px solid #5271ff;
}
.inputLoc {
  border: 1px solid #999999;
  padding: 10px;
  font-size: 12px;
  border-radius: 6px;
  width: 100%;
  text-align: right;
  font-size: 12px;

  padding-left: 100px;
  background-color: #dcdcdc;
}
.inputLoc:focus {
  outline: none;
  border: 2px solid #5271ff;
}
.inputError {
  border: 1px solid red;
  padding: 10px;
  font-size: 12px;
  border-radius: 6px;
  width: 100%;
  text-align: right;
  font-size: 12px;

  padding-left: 100px;
}
.checkboxGroup {
  cursor: pointer;
  align-items: start;
  display: flex;
  gap: 8px;
  padding-inline: 5%;
}

.checkboxGroup > input {
  margin-top: 6px;
}

.modal {
  h1 {
    font-size: 20px;
    font-weight: 700;
  }
  h2,
  li::marker {
    font-size: 16px;
    font-weight: 700;
  }
  p {
    font-weight: 500;
    font-size: 16px;
  }
  ol {
    display: grid;
    gap: 1rem;
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.button {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 15px;
  margin-left: 100px;
  margin-right: 100px;
}

.inputContainer {
  position: relative;
  width: 100%;
}
.inputContainerLoc {
  position: relative;
  width: 100%;
  opacity: 0.5;
}

.icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0.5;
  font-size: 12px;
}

.iconLoc {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  // opacity: 0.5;
  font-size: 14px;
}
.iconError {
  position: absolute;
  top: 30%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0.5;
  font-size: 12px;
}
.leftTd {
  opacity: 0.5;
  text-align: left;

  font-size: 12px;
  padding: 15px;
  width: 35%;
}

.rightTd {
  opacity: 0.8;
  text-align: right;

  font-size: 12px;
  padding: 10px;
}
.rightTdLoc {
  opacity: 0.5;
  text-align: right;

  font-size: 12px;
  padding: 15px;
}

.rows {
  border-bottom: solid;
  border-top: none;
  border-width: thin;
  border-right: none;
  border-left: none;
  border-color: #e0e0e0;
}

.bottomRow {
  border-style: none;
}

.error {
  background-color: rgba(220, 53, 69, 0.2);
  display: flex;
  align-items: center;
  font-size: 12px;

  margin-top: 5px;
  padding-left: 12px;
  border-radius: 3px;
  height: 25px;
}

.copied {
  background-color: rgb(218, 249, 220);
  position: absolute;
  bottom: 0;
  left: 50%;
  padding: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 10px;

  border-radius: 5px;
}
