.card {
  outline: 1px solid #dee2e6;
  box-shadow: 0px 5px 53px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 5px 53px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 53px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 20px;
  background-color: #ffffff;
}

.card:not(.disabled).interactive {
  cursor: pointer;
}

.card.active {
  outline: 3px solid #5271ff;
}

.card:not(.disabled).interactive:hover {
  outline: 3px solid #0e3ed9;
  background-color: #f0f2ff;
}

// Title
.title {
  margin: 0;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
  
  text-align: left;
  color: #000000;
  padding-left: 20px;
}

.card.active .title {
  color: #5271ff;
}

.card:not(.disabled).interactive:hover .title {
  color: #0e3ed9;
}

.card.disabled .title {
  color: #d9d9d9;
}

// Subtitle
.subtitle {
  margin: 0;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  color: #979797;
  padding-left: 20px
}

//  Image
.img {
  border-radius: 50px;
  width: 32vw;
  height: 43vh;
  padding: 20px;
}

// Link
.link {
  display: inline-flex;
  // gap: 4px;
  color: #5271ff;
  font-size: 14px;
  font-weight: 600;
  border: 0;
  background-color: inherit;
  align-items: center;
  justify-content: end;
}

.link:hover {
  border-bottom: 2px solid #5271ff;
  margin-bottom: -2px;
}
