$mobile-card-height: 120px;

@media (max-width: 650px) {
  .card {
    margin: 50px 55px 0px 60px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
  }
}
@media (max-width: 574px) {
  .layout {
    padding-bottom: calc(10px + $mobile-card-height);
  }
}
