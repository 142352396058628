p {
  margin-bottom: 0;
}

.container {
  color: #000000;
  display: grid;
  width: 100%;
  grid-template-areas: 
  ". . . . . . . . cl"
  "sw sw sw sw sw sw ti ti ti"
  "sw sw sw sw sw sw sa sa sa"
  "sw sw sw sw sw sw ty ty ty"
  "sw sw sw sw sw sw de de de"
  "sw sw sw sw sw sw ta ta ta"
  "sw sw sw sw sw sw ta ta ta"
  "sw sw sw sw sw sw ta ta ta"
  "sw sw sw sw sw sw ta ta ta"
  "fp fp fp ut ut ut pp pp pp"
  "fp fp fp ut ut ut . . ."
  "fp fp fp ut ut ut bf bf bf";
}

// .container :global(.slick-list) {
//   padding: 0 !important;
// }
// .container :global(.slick-next::before),
// .container :global(.slick-prev::before) {
//   display: none;
// }

.container * {
  line-height: 1.5rem;
  
}

.shortAddress {
  font-size: 18px;
  font-weight: 300;
  color: #979797;
  grid-area: ti;
  margin-bottom: 0px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
}

.address {
  color: #979797;
  // font-weight: 400;
  font-size: 16px;
  grid-area: sa;
  margin-top: 0px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.descriptionsGroup {
  display: grid;
}

.h3 {
  font-weight: 600;
  font-size: 16px;
}

.h4 {
  font-weight: 600;
  font-size: 14px;
}

.description {
  font-size: 12px;
  font-weight: 400;
  
}

.parking {
  text-transform: capitalize;
}

.leaseOptions {
  padding: 8px;
  border-radius: 10px;
  background-color: #F9F9F9;
  grid-area: ta;
  margin-bottom: 20px !important;
  margin: 10px;
  box-shadow: 0px 5px 5px #CCCCCC;
}

@media (min-width: 750px) {
  .shortAddress {
    font-weight: 700;
    font-size: 24px;
  }
  .address {
    font-weight: 600;
    font-size: 16px;
  }
  .descriptionsGroup {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .h4 {
    font-size: 14px;
    color: #9499a1;
  }
}
.swiperContainer{
  width: 500px;
  grid-area: sw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.petPolicies{
  grid-area: pp;
  margin: 10px;
}

.floorPlan{
  grid-area: fp;
  margin-left: 20px;
  margin-top: 10px;
  padding-right: 20px;
};

.utilities{
  grid-area: ut;
  margin: 10px;
  padding-right: 10px;
}

.buildingFeatures{
  grid-area: bf;
  margin: 10px;
}

.type{
  grid-area: ty;
  margin-left: 10px;
  margin-top: 10px;
  font-weight: 500;
  color: #979797;
  font-size: 20px;
  
  text-transform: capitalize;
}

.details{
  grid-area: de;
  margin: 10px;
  opacity: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  // font-weight: 400;
  font-size: 12px;
}

.line{
  height: 20px;
  background-color: black;
  opacity: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1px;
  padding: 0px;
  margin-right: 5px;
  margin-left: 5px;
  display: inline-block;
}
.tableHeader{
  opacity: 0.8;
  text-align: left;
  
  font-size: 16px;
  padding: 5px;
  font-weight: 100;
}
.leftTd{
  opacity: 0.7;
  text-align: left;
  
  font-size: 12px;
  padding: 10px;
}

.rightTd{
  opacity: 0.8;
  text-align: right;
  
  font-size: 12px;
  padding: 15px;
}

.rows{
  border-bottom: solid rgba(153, 153, 153, 0.2);
  border-top: none;
  border-width: thin;
  border-right: none;
  border-left: none;
  width: 100%;
  
}

.bottomRow{
  border-style: none;
}

.table{
  width: 100%
}

.close{
  grid-area: cl;
  padding: 0px;
  margin: 0px;
}

@media (min-width: 360px) and (max-width: 429px){
  .container {
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 370px;
    padding: 20px;
  }
  
  // .container :global(.slick-list) {
  //   padding: 0 !important;
  // }
  // .container :global(.slick-next::before),
  // .container :global(.slick-prev::before) {
  //   display: none;
  // }
  
  .container * {
    line-height: 1rem;
    
  }
  
  .shortAddress {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    grid-area: ti;
    margin-bottom: 5px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .address {
    width: 100%;
    color: #979797;
    // font-weight: 400;
    font-size: 12px;
    grid-area: sa;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  
  .descriptionsGroup {
    display: grid;
  }
  
  .h3 {
    font-weight: 600;
    font-size: 16px;
  }
  
  .h4 {
    font-weight: 600;
    font-size: 14px;
  }
  
  .description {
    font-size: 12px;
    font-weight: 400;
    
  }
  
  .parking {
    text-transform: capitalize;
  }
  
  .leaseOptions {
    padding: 8px;
    border-radius: 10px;
    background-color: #F9F9F9;
    grid-area: ta;
    margin-bottom: 20px !important;
    margin: 10px;
    box-shadow: 0px 5px 5px #CCCCCC;
    width: 100%;
  }
  .swiperContainer{
    width: 370px;
    grid-area: sw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .petPolicies{
    grid-area: pp;
    margin: 10px;
    width: 100%;
    
  }
  
  .floorPlan{
    grid-area: fp;
    margin: 10px;
    width: 100%;
    padding-right: 0px;

  };
  
  .utilities{
    grid-area: ut;
    margin: 10px;
    width: 100%;
    padding-right: 0px;
  }
  
  .buildingFeatures{
    grid-area: bf;
    padding-right: 0px;
    margin: 10px;
    width: 100%;
  }
  
  .type{
    grid-area: ty;
    margin-left: 0px;
    margin-top: 10px;
    font-weight: 800;
    width: 100%;
    color: #979797;
    font-size: 12px;
    
    text-transform: capitalize;
  }
 
 
  .details{
    grid-area: de;
    margin: 10px;
    opacity: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-weight: 400;
    font-size: 12px;
    width: 100%;
  }
  
  .line{
    height: 20px;
    background-color: black;
    opacity: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1px;
    padding: 0px;
    margin-right: 25px;
    margin-left: 25px;
    display: inline-block;
  }
  .tableHeader{
    opacity: 0.8;
    text-align: left;
    
    font-size: 16px;
    padding: 5px;
    font-weight: 100;
  }
  .leftTd{
    opacity: 0.7;
    text-align: left;
    
    font-size: 12px;
    padding: 10px;
  }
  
  .rightTd{
    opacity: 0.8;
    text-align: right;
    
    font-size: 12px;
    padding: 15px;
  }
  
  .rows{
    border-bottom: solid rgba(153, 153, 153, 0.2);
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    width: 100%;
    
  }
  
  .bottomRow{
    border-style: none;
  }
  
  .table{
    width: 100%
  }
  
  .close{
    grid-area: cl;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-self: start !important;
  }
  
  .closeContainer{
    width: 100%;
    margin: 0px;
    display: flex;
    justify-content: start;
    align-items: start;
  }
  
}

@media (min-width: 430px) and (max-width: 767px){
  .container {
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;
    padding: 20px;
  }
  
  // .container :global(.slick-list) {
  //   padding: 0 !important;
  // }
  // .container :global(.slick-next::before),
  // .container :global(.slick-prev::before) {
  //   display: none;
  // }
  
  .container * {
    line-height: 1rem;
    
  }
  
  .shortAddress {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    grid-area: ti;
    margin-bottom: 5px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .address {
    color: #979797;
    width: 100%;
    // font-weight: 400;
    font-size: 16px;
    grid-area: sa;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  
  .descriptionsGroup {
    display: grid;
  }
  
  .h3 {
    font-weight: 600;
    font-size: 16px;
  }
  
  .h4 {
    font-weight: 600;
    font-size: 14px;
  }
  
  .description {
    font-size: 12px;
    font-weight: 400;
    
  }
  
  .parking {
    text-transform: capitalize;
  }
  
  .leaseOptions {
    padding: 8px;
    border-radius: 10px;
    background-color: #F9F9F9;
    grid-area: ta;
    margin-bottom: 20px !important;
    margin: 10px;
    box-shadow: 0px 5px 5px #CCCCCC;
    width: 100%;
  }
  .swiperContainer{
    width: 400px;
    margin-top: 10px;
    grid-area: sw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .petPolicies{
    grid-area: pp;
    margin: 10px;
    width: 100%;
    padding-right: 0px;
  }
  
  .floorPlan{
    grid-area: fp;
    margin: 10px;
    width: 100%;
    padding-right: 0px;

  };
  
  .utilities{
    grid-area: ut;
    margin: 10px;
    width: 100%;
    padding-right: 0px;
  }
  
  .buildingFeatures{
    grid-area: bf;
    margin: 10px;
    width: 100%;
  }
  
  .type{
    grid-area: ty;
    margin-left: 0px;
    margin-top: 10px;
    font-weight: 800;
    width: 100%;
    color: #979797;
    font-size: 16px;
    
    text-transform: capitalize;
  }
  .typeContainer{
    width: 100%;
    display: flex;
    justify-content: start;
  }
  
  .details{
    grid-area: de;
    margin: 10px;
    opacity: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-weight: 400;
    font-size: 14px;
    width: 100%;
  }
  
  .line{
    height: 20px;
    background-color: black;
    opacity: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1px;
    padding: 0px;
    margin-right: 25px;
    margin-left: 25px;
    display: inline-block;
  }
  .tableHeader{
    opacity: 0.8;
    text-align: left;
    
    font-size: 16px;
    padding: 5px;
    font-weight: 100;
  }
  .leftTd{
    opacity: 0.7;
    text-align: left;
    
    font-size: 12px;
    padding: 10px;
  }
  
  .rightTd{
    opacity: 0.8;
    text-align: right;
    
    font-size: 12px;
    padding: 15px;
  }
  
  .rows{
    border-bottom: solid rgba(153, 153, 153, 0.2);
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    width: 100%;
    
  }
  
  .bottomRow{
    border-style: none;
  }
  
  .table{
    width: 100%
  }
  
  .close{
    grid-area: cl;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-self: start !important;
  }
  
  .closeContainer{
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
  }
  
  
}