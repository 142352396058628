.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90px;
  position: relative;
}

.circleWrapper {
  position: relative; 
  margin-left: 15px;
  width: 100%;
  height: 100%;
}


.circle {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 31px;
  width: 45px;
  aspect-ratio: 1;
  border-radius: 100px;
  font-weight: 700;
  background-color: #00000033;
  position: absolute;
}


#circle1 {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

#circle2 {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

#circle3 {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

#circle4 {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.completed .line {
  background-color: #5271ff;
}

.line {
  position: absolute;
  background-color: #00000033;
  z-index: -1;
}


.line1 {
  height: 2px; 
  width: 50px; 
  top: 25px; 
  left: 45px; 
}

.default .circle {
  background-color: #00000033;
}

.selected .circle {
  background-color: #5271ff;
}

.completed .circle {
  background-color: #5271ff;
}

.hideLineForStep3 .line {
  display: none;
}

@media (min-width: 360px) and (max-width: 767px){
  .line1 {
    height: 2px; 
    width: 45px; 
    top: 25px; 
    left: 45px; 
  }
}








