.container {
  display: grid;
  gap: 12px;
  place-content: center;
}
.container > * {
  max-width: 600px;
  width: 100%;
}
.error {
  border: 1px solid red; 
}
.card {
  display: grid;
  gap: 8px;
  margin-top: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
}

.input {
  border: 1px solid #dee2e6;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  border-radius: 6px;
  
  width: 100%;
}
.input:focus{
  outline: none;
  border: solid 2px #5271ff !important;
}

.inputError {
  border: 1px solid #FF0000;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  border-radius: 6px;
  
  width: 100%;
}

.checkboxGroup {
  cursor: pointer;
  align-items: start;
  display: flex;
  gap: 8px;
  padding-inline: 5%;
}

.checkboxGroup > input {
  margin-top: 6px;
}

.modal {
  
  h1 {
    font-size: 20px;
    font-weight: 700;
  }
  h2,
  li::marker {
    font-size: 16px;
    font-weight: 700;
  }
  p {
    font-weight: 500;
    font-size: 16px;
  }
  ol {
    display: grid;
    gap: 1rem;
  }
  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.closeButton{
  background: none;
  border: none;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
  margin: 10px
}
.closeContainer{
  display: flex;
  justify-content: end;
  align-items: end;
}
.icon {
  position: absolute;
    top: 48%;
    left: 10px; 
    transform: translateY(-50%);
    pointer-events: none; 
    opacity: 0.5;
    font-size: 12px;
    
}
.iconError{
  position: absolute;
    top: 51%;
    left: 10px; 
    transform: translateY(-50%);
    pointer-events: none; 
    opacity: 0.5;
    font-size: 12px;
    
    margin-top: -16.5px;
}

.error {
  background-color: rgba(220, 53, 69, 0.2);
  color: #4C4D52;
  border: none;
  display: flex;
  align-items: center;
  font-size: 10px;
  
  margin-top: 5px;
  padding-left: 12px;
  border-radius: 3px;
  height: 25px
}
.errorTerms{
  background-color: rgba(220, 53, 69, 0.2);
  color: #4C4D52;
  border: none;
  display: flex;
  align-items: center;
  font-size: 10px;
  
  margin-top: 5px;
  margin-left: 20px;
  padding-left: 12px;
  border-radius: 3px;
  width: 95%;
  height: 25px
}

.errorContainer{
  position: absolute;
  top: 94%;
  left: 43%;
  z-index:1000
}

.inputContainer {
  position: relative;
  width: 100%;
}

.agreement{
  
  font-size: 10px;
}

@media (min-width: 360px) and (max-width: 767px){
  .container {
    display: grid;
    gap: 12px;
    place-content: center;
  }
  .container > * {
    max-width: 600px;
    width: 100%;
  }
  
  .card {
    display: grid;
    gap: 8px;
    margin-top: 80px;
  }
  
  .input {
    border: 1px solid #dee2e6;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    border-radius: 6px;
    
    width: 100%;
  }

  .error {
    background-color: rgba(220, 53, 69, 0.2);
    color: #4C4D52;
    border: none;
    display: flex;
    align-items: center;
    font-size: 8px;
    
    margin-top: 5px;
    padding-left: 12px;
    border-radius: 3px;
    height: 25px
  }
  .errorTerms{
    background-color: rgba(220, 53, 69, 0.2);
    color: #4C4D52;
    border: none;
    display: flex;
    align-items: center;
    font-size: 8px;
    
    margin-top: 5px;
    margin-left: 20px;
    padding-left: 12px;
    border-radius: 3px;
    width: 92%;
    height: 25px
  }
  
  .checkboxGroup {
    cursor: pointer;
    align-items: start;
    display: flex;
    gap: 8px;
    padding-inline: 5%;
  }
  
  .checkboxGroup > input {
    margin-top: 6px;
  }
  
  .modal {
    
    h1 {
      font-size: 20px;
      font-weight: 700;
    }
    h2,
    li::marker {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-weight: 500;
      font-size: 16px;
    }
    ol {
      display: grid;
      gap: 1rem;
    }
    .buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .icon {
    position: absolute;
      top: 48%;
      left: 10px; 
      transform: translateY(-50%);
      pointer-events: none; 
      opacity: 0.5;
      font-size: 12px;
      
  }
  
  .inputContainer {
    position: relative;
    width: 100%;
  }
  
  .agreement{
    
    font-size: 10px;
  }
}