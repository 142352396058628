.react-datepicker {
  border: solid;
}

.react-datepicker {
  .react-datepicker__navigation {
    &--next {
      right: 7px !important;
      @media (min-width: 767px) {
        right: 7px !important;
      }
    }
  }
}

.react-datepicker__month-container {
  box-shadow: none;
}

.react-datepicker__navigation {
  top: 9px !important;
  &--next,
  &--previous {
    > span {
      display: block !important;
    }
  }

  &--next {
  }

  &--previous {
    left: 5px !important;
  }
  @media (min-width: 767px) {
    top: 25px !important;
    &--next,
    &--previous {
      > span {
        display: block !important;
      }
    }

    &--next {
    }

    &--previous {
      left: 5px !important;
    }
  }
}

.filter-popup {
  .react-datepicker__navigation {
    top: 0 !important;
    @media (min-width: 767px) {
      top: 15px !important;
    }
  }
}

.react-datepicker__day-names {
  margin-top: 0;
  border-top: 1px solid #dee2e6;
  max-width: 85%;
  margin-inline: auto;
}

.react-datepicker__day {
  width: 2rem;
  height: 2rem;
  font-weight: 500;
  font-size: 0.9rem;
}

.react-datepicker-time__caption {
  display: none !important;
}
.react-datepicker-time__input-container,
.react-datepicker-time__input {
  width: 100%;
}
.react-datepicker__input-time-container {
  margin: 0;
}
.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input {
  margin-left: 0px;
}

.react-datepicker__time-list-item--selected {
  background-color: #f5f5f5 !important;
  color: black !important;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px !important;
  border-radius: 8px;
}
@media (min-width: 767px) {
  .react-datepicker {
    border-radius: 10px;
    border-color: rgba(0, 0, 0, 0.5);
    padding: 16px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 5px 53px 0px rgba(0, 0, 0, 0.15);
  }
  .react-datepicker__navigation {
    top: 30px;
  }
  .react-datepicker__navigation--next {
    right: 50px !important;
  }
  .react-datepicker__time-container {
    // margin-left: 10px;
    border-left: none;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    scrollbar-width: 4px;
    scrollbar-color: #979797;
  }
  .react-datepicker__time-container {
    width: 83px;
  }
}
@media (min-width: 360px) and (max-width: 429px) {
  .react-datepicker__time-container {
    width: 225px;
  }
  .react-datepicker__time-box {
    width: 225px !important;
    text-align: left !important;
  }
  .react-datepicker__time-list-item--selected {
    background-color: #f5f5f5 !important;
    color: black !important;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px !important;
    border-radius: 8px;
  }
}

@media (min-width: 430px) and (max-width: 767px) {
  .react-datepicker__time-container {
    width: 225px;
  }
  .react-datepicker__time-box {
    width: 225px !important;
    text-align: left !important;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .react-datepicker__time-container {
    width: 225px;
  }
  .react-datepicker__time-box {
    width: 225px !important;
    text-align: left !important;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none !important;
}
