.layout {
  padding-block: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cardTitle {
  // font-weight: 600;
  opacity: 50%;
  font-size: 12px;
  text-align: start;
}
.heading {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 24px;
  font-weight: 700;
  color: #5271ff;
}
.header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  max-width: 80%;
  margin-inline: auto;
}
.containerRes {
  max-width: 80%;
  margin-left: 30px;
  margin-top: 0px;
}

.stepLabels {
  margin-bottom: 12px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-direction: column-reverse;
  margin-top: 15px;
}

.button {
  display: flex;
  gap: 12px;
  justify-content: center;
  width: 100%;
}
.headerTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 0;
  color: #5271ff;
  
}
.headerSubtitle{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  color: #979797;
  
}

.procModal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  gap: 10px;
  text-align: center;
  
}

.modalTitle{
  color: #4C4D52;
  font-size: 20px;
  font-weight: 900 !important;
  
}

.modalDesc{
  color: #8C8C8C;
  font-size: 16px;
  margin: 10px 0;
}
.modalButton{
  width: 200px;
  height: 45px
}

// @media (max-width: 750px) {
//   .inactive {
//     display: none;
//   }
// }

@media (min-width: 751px) {
  .stepLabels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    gap: 5%;
  }
  .buttons {
    gap: 20px;
    flex-direction: row;
    margin-top: 24px;
    
  }
  .button{
    width: 50%
  }
}

@media (min-width: 360px) and (max-width: 429px){
  .headerTitle{
    font-size:20px;
  }
  .headerSubtitle{
    font-size: 12px;
  }
  .stepLabels{
    display: flex;
    margin-left: 25px;
  }
  .modalButton{
    width: 170px;
    height: 45px;
    font-size: 14px !important;
  }
}

@media (min-width: 430px) and (max-width: 767px){
  .headerTitle{
    font-size:24px;
  }
  .headerSubtitle{
    font-size: 14px;
  }
  .stepLabels{
    display: flex;
    margin-left: 25px;
  }
  .modalButton{
    width: 180px;
    height: 45px
  }
}



