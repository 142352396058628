.searchWrapper {
    width: 100%;
    min-height: 44px !important;
    padding: 0.25rem 0.5rem;
    margin: 10px 0;
}

.option {
    font-size: 0.875rem;
    
}


#search_input {
    margin: 0;
    padding-top: 5px;
}

.multiSelectContainer input::placeholder {
    font-size: 0.875rem;
    
    line-height: 1.5rem;
}

.select-icon {
    font-size: 1.6rem;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%); 
    color: grey;
}

span.chip{
    background: #4067F9;
}

.multiSelectContainer li:hover {
    background: #4067F9 !important;
}