.myTimePickerContainer {
  position: relative;
  :global(.react-datepicker-wrapper) {
    display: block;
  }
  :global(.react-datepicker-popper) {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    z-index: 101;
  }
}

.myTimePicker {
  display: block;
  width: 80%;
  margin-inline: auto;
  background-color: #f0f2ff;
  color: #5271ff;
  font-size: 15px;
  font-weight: 700;
  padding: 18px;
  border-radius: 14px;
  border: none;
}

.container {
  padding: 0px;
  margin-top: 50px;
  margin-right: 100px;
  margin-bottom: 75px;
  width: 400px;
  border-radius: 10px;
  height: 200px;
  aspect-ratio: 1;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15) !important;
  
}

input[type='time' i]::-webkit-calendar-picker-indicator {
  background-image: url('../../../../../assests/images/clock.svg');
}

.customInputContent {
  display: flex;
  justify-content: space-between;
}

.clock {
  position: absolute;
  right: 42px;
  top: calc(50% - 14px);
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.heading{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  
}

.line{
  height: 1px;
  width: 360px;
  background-color: black;
  opacity: 25%;
  margin-left: 20px;
}


.label{
  font-size: 14px;
  
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 83px;
}

.label2{
  font-size: 14px;
  
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 88px;
}
.inputBox {
  border: 1px solid #dee2e6;
  margin-top: 15px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  border-radius: 6px;
  width:65%;
  font-size: 12px;
  
}

.inputBox:focus{
  outline: none;
  border: solid 2px #5271ff !important;
}
.icon {
  :hover{
    cursor: pointer;
  }
  position: absolute;
  top: 65%;
  left: 355px;
  transform: translateY(-50%);
  pointer-events: none; 
  opacity: 0.5;
  font-size: 12px;
  border: none;
  background: none;
  
}

.inputContainer {
  position: relative;
  width: 100%;
}

.datePickerWrapper{
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  margin-top: 5px;
  margin-left: 10px;
  top: -155%;
  left: 405px;
}

.timePickerWrapper{
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  margin-top: 5px;
  margin-left: 10px;
  top: -255%;
  left: 405px;
}

@media (min-width: 360px) and (max-width: 429px){
  .myTimePicker {
    display: block;
    width: 80%;
    margin-inline: auto;
    background-color: #f0f2ff;
    color: #5271ff;
    font-size: 15px;
    font-weight: 700;
    padding: 18px;
    border-radius: 14px;
    border: none;
  }
  .noProperty{
    margin-top: 50px;
  }
  .container {
    padding: 0px;
    margin-top: 50px;
    margin-right: 80px;
    margin-bottom: 50px;
    width: 340px;
    border-radius: 10px;
    height: 180px;
    aspect-ratio: 1;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15) !important;
    
  }
  
  input[type='time' i]::-webkit-calendar-picker-indicator {
    background-image: url('../../../../../assests/images/clock.svg');
  }
  
  .customInputContent {
    display: flex;
    justify-content: space-between;
  }
  
  .clock {
    position: absolute;
    right: 42px;
    top: calc(50% - 14px);
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
  
  .heading{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    
    font-size: 12px;
  }
  
  .line{
    height: 1px;
    width: 300px;
    background-color: black;
    opacity: 25%;
    margin-left: 20px;
  }
  
  
  .label{
    font-size: 12px;
    
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 63px;
  }
  
  .label2{
    font-size: 12px;
    
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 68px;
  }
  .inputBox {
    border: 1px solid #dee2e6;
    margin-top: 15px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    border-radius: 6px;
    width:65%;
    font-size: 12px;
    
  }
  .inputBox:focus{
    outline: none;
    border: solid 2px #5271ff !important;
}
  
  .icon {
    :hover{
      cursor: pointer;
    }
    position: absolute;
    top: 65%;
    left: 300px;
    transform: translateY(-50%);
    pointer-events: none; 
    opacity: 0.5;
    font-size: 12px;
    border: none;
    background: none;
    
  }
  
  .inputContainer {
    position: relative;
    width: 100%;
  }
  
  .datePickerWrapper{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 100%;
    left: 35px;
  }
  
  .timePickerWrapper{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 100%;
    left: 85px;
  }
}

@media (min-width: 430px) and (max-width: 767px){
  .myTimePicker {
    display: block;
    width: 80%;
    margin-inline: auto;
    background-color: #f0f2ff;
    color: #5271ff;
    font-size: 15px;
    font-weight: 700;
    padding: 18px;
    border-radius: 14px;
    border: none;
  }
  .noProperty{
    margin-top: 50px;
  }
  
  .container {
    padding: 0px;
    margin-top: 80px;
    margin-right: 80px;
    margin-bottom: 50px;
    width: 340px;
    border-radius: 10px;
    height: 180px;
    aspect-ratio: 1;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15) !important;
    
  }
  
  input[type='time' i]::-webkit-calendar-picker-indicator {
    background-image: url('../../../../../assests/images/clock.svg');
  }
  
  .customInputContent {
    display: flex;
    justify-content: space-between;
  }
  
  .clock {
    position: absolute;
    right: 42px;
    top: calc(50% - 14px);
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
  
  .heading{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    
    font-size: 12px;
  }
  
  .line{
    height: 1px;
    width: 300px;
    background-color: black;
    opacity: 25%;
    margin-left: 20px;
  }
  
  
  .label{
    font-size: 12px;
    
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 63px;
  }
  
  .label2{
    font-size: 12px;
    
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 68px;
  }
  .inputBox {
    border: 1px solid #dee2e6;
    margin-top: 15px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    border-radius: 6px;
    width:65%;
    font-size: 12px;
    
  }
  .inputBox:focus{
    outline: none;
    border: solid 2px #5271ff !important;
}
  .icon {
    :hover{
      cursor: pointer;
    }
    position: absolute;
    top: 65%;
    left: 300px;
    transform: translateY(-50%);
    pointer-events: none; 
    opacity: 0.5;
    font-size: 12px;
    border: none;
    background: none;
    
  }
  
  .inputContainer {
    position: relative;
    width: 100%;
  }
  
  .datePickerWrapper{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 100%;
    left: 35px;
  }
  
  .timePickerWrapper{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 100%;
    left: 85px;
  }
}
@media (min-width: 767px) and (max-width: 1024px){
  
  .datePickerWrapper{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 100%;
    left: 65px;
  }
  
  .timePickerWrapper{
    position: absolute;
    z-index: 1000;
    background-color: #ffffff;
    margin-top: 5px;
    margin-left: 10px;
    top: 100%;
    left: 115px;
  }
}