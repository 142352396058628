.drawer {
  max-height: 90vh !important;
  border-radius: 20px 20px 0 0;
}

.drawer .content {
  padding: 1rem;
}

.modal {
  min-width: 820px;
  padding: 1.5rem;
  border-radius: 20px !important;
}

.handle {
  margin-inline: auto;
  display: block;
  padding-block: 10px;
}
