.form-select {
    padding-right: 35px;
}

.card-body {
    .form-switch {
        .form-check-input {
            width: 2em;
            margin-left: -2.5em;
            border-radius: 2em;
        }
    }
}