.container {
  display: grid;
  // gap: 0px;
  grid-template-columns: minmax(0, 1fr);
}

.container :global(.slick-next::before),
.container :global(.slick-prev::before) {
  display: none;
}

.container :global(.slick-list) {
  padding: 12px 12px !important;
  box-sizing: initial !important;
}

.companyName {
  justify-self: center;
  font-weight: 700;
  font-size: 24px;
}

@media (max-width: 767px) {
  .companyName {
    display: none;
  }
}

.swiper :global(.swiper-initialized) {
  padding: 1rem;
}
.swiperContainer{
  padding-top: 50px;
  padding-bottom: 40px;
  width: 50%;
  border-radius: 20px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.swiperSlide{
  display: flex;
  width: 80%;
  height: 80%;
  justify-content: center;
  align-items: center;
}

@media (min-width: 360px) and (max-width: 429px){
  .container{
    display: flex;
    flex-direction: column;
    
  }
  .swiperContainer{
    margin-top: 50px;
    padding-bottom: 40px; 
    width: 350px;
    height: 450px;
    border-radius: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .swiperSlide{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 430px) and (max-width: 767px){
  .container{
    display: flex;
    flex-direction: column;
  }
  .swiperContainer{
    margin-top: 50px;
    padding-bottom: 40px; 
    width: 400px;
    height: 500px;
    border-radius: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .swiperSlide{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 767px) and (max-width: 2400px){
  .container{
    display: flex;
    flex-direction: column;
  }
  .swiperContainer{
    margin-top: 0px;
    padding-bottom: 40px; 
    width: 600px;
    // height: 700px;
    border-radius: 20px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  .swiperSlide{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}