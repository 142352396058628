//Installation CSS
.button1{
    width: 250px;
    display: flex; 
    justify-content: center;
    
    background-color: #F5F5F5;
    margin-bottom: 30px;
}
.button2{
    width: 250px; 
    display: flex; 
    justify-content: center; 
     
    background-color: #5271FF;
    margin-bottom: 30px;

}

.button3{
    width: 30px; 
    height: 30px;
    // display: flex; 
    // justify-content: center; 
     
    background-color: #5271FF;
    margin-right: 30px;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
}


.card {
    display: grid;
    // gap: 12px;
    margin-top: 0px;
    margin-bottom: 25px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 0px;
   box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
  }
  .orderCard {
    display: grid;
    // gap: 12px;
    margin-top: 0px;
    margin-bottom: 25px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 0px;
   box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  }
  
  .input {
    border: 1px solid #ced4da;
    padding: 8px 10px 8px 10px;
    font-size: 12px;
    border-radius: 4px;
    width:100%;
    font-size: 12px;
    
  }
  .input:focus{
      outline: none;
      border: 1px solid #5271ff;
  }
  .input::placeholder{
    font-size: 13px;
    color:#adb5bd;
  }

  .inputError{
    border: 1px solid red;
    padding: 10px;
    font-size: 12px;
    border-radius: 6px;
    width:100%;
    text-align: right;
    font-size: 12px;
    
    padding-left: 100px;
  }
  .checkboxGroup {
    cursor: pointer;
    align-items: start;
    display: flex;
    gap: 8px;
    padding-inline: 5%;
  }
  
  .checkboxGroup > input {
    margin-top: 6px;
  }
  .button {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: 100px;
    margin-right: 100px;
  }

  .inputContainer {
    position: relative;
    width: 95%;
    margin: 0px 20px 20px 30px;
  }
  .inputContainerLoc {
    position: relative;
    width: 100%;
    opacity: 0.5;
  }
  
  .icon{
    color: rgb(0, 0, 0, 0.3);
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }
  
  .iconError{
    position: absolute;
    top: 30%;
    left: 10px; 
    transform: translateY(-50%);
    pointer-events: none; 
    opacity: 0.5;
    font-size: 12px;
    
  }
  .leftTd{
    text-align: left;
    
    font-size: 12px;
    padding: 15px;
    color: #4C4D52;
    font-weight: 600;
  }
  .leftTdTot{
    text-align: left;
    
    font-size: 12px;
    padding: 15px;
    // color: #4C4D52;
    font-weight: 800;
  }

  .rightTd{
    text-align: right;
    
    font-size: 12px;
    padding: 10px;
    color: #4C4D52;
    font-weight: 400;
  }
  .midTd{
    
    font-size: 12px;
    padding: 10px;
    color: #4C4D52;
    font-weight: 400;
  }
  .rightTdTot{
    text-align: right;
    
    font-size: 12px;
    padding: 10px;
    font-weight: 800;
  }

  .rows{
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
  }

  .bottomRow{
    border-style: none;
  }

  .error {
    background-color: rgba(220, 53, 69, 0.2);
    display: flex;
    align-items: center;
    font-size: 12px;
    
    margin-top: 5px;
    padding-left: 12px;
    border-radius: 3px;
    height: 25px
}

.inputLabel{
    font-size: 12px;
    font-weight: 800;
    
    color: #4C4D52;
    margin-bottom: 5px;
}

.labelNameEmail{
    font-size: 12px;
    font-weight: 800;
    
    color: #4C4D52;
    margin-bottom: 5px;
}
.labelNameEmail:hover:after{
    content: attr(data-tip);
    color: #4C4D52;   
    display: block;
    position: relative;
    font-size: 8px;
    top: 0px;
    left: 55%;
    padding: 2px;
    border-radius: 2px;
    margin-top: -16.5px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
}
.labelNumComp{
    font-size: 12px;
    font-weight: 800;
    
    color: #4C4D52;
    margin-bottom: 5px;
}
.labelNumComp:hover:after{
    content: attr(data-tip);
    color: #4C4D52;   
    display: block;
    position: relative;
    font-size: 8px;
    top: 0px;
    left: 100%;
    padding: 2px;
    border-radius: 2px;
    margin-top: -16.5px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
}
.labelTime{
  font-size: 12px;
  font-weight: 800;
  
  color: #4C4D52;
  margin-bottom: 5px;
}
.labelTime:hover:after{
  content: attr(data-tip);
  color: #4C4D52;   
  display: block;
  position: relative;
  font-size: 8px;
  top: 0px;
  left: 70%;
  padding: 2px;
  border-radius: 2px;
  margin-top: -16.5px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
}
.labelAdd{
    font-size: 12px;
    font-weight: 800;
    
    color: #4C4D52;
    margin-bottom: 5px;
}
.labelAdd:hover:after{
    content: attr(data-tip);
    color: #4C4D52;   
    display: block;
    position: relative;
    font-size: 8px;
    top: 0px;
    left: 65%;
    padding: 2px;
    border-radius: 2px;
    margin-top: -16.5px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
}
  
.formHeader{
    display: flex;
    // justify-content: center;
    align-items: center;
    justify-content: space-between;
    background-color: #EAEAEB;
    height: 50px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    font-size: 14px;
    
    font-weight: 600;
    margin-bottom: 20px;
}

.headerRow{
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #979797;
    
    font-weight: 600;
    margin-right: 15px;
    margin-left: 15px;
    border-bottom: solid;
    border-top: none;
    border-width: thin;
    border-right: none;
    border-left: none;
    border-color: #e0e0e0;
}

.kitName{
    margin-left: 10px;
    color: #4C4D52;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

.datePickerWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin: 20px;
   
  }

  .modal {
    
    h1 {
      font-size: 20px;
      font-weight: 700;
      margin-left: 140px
    }
    h2,
    li::marker {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-weight: 500;
      font-size: 16px;
    }
    ol {
      display: grid;
      gap: 1rem;
    }
    .buttons {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      margin: 0 50px 0 50px;
      color: #4C4D52
    }
  }

  .closeIcon{
    position: absolute;
    top: 33px;
    left: 97.5%;
    opacity: 0.5;
  }

  .closeIcon:hover{
    cursor: pointer;
  }