  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin-top: 15px;
    padding: 0 200px;
  }

  .button-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    padding: 0;
    margin: 0;
    padding-inline: 0 !important;
  }

  .title{
    /* margin-top: 100px; */
    
    font-weight: 700 !important;
    font-size: 23px;
    line-height: 34.5px;
    margin-bottom: 8px !important;
    color: #444444;
  }
  .subtitle{
    
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #999999 !important;
  }

  .leftContainer{
    height: 800px
  }

  .stepper{
    display: none !important;
  }

  .button {
    width: 100%;
    margin-top: 40px;
  }
  .buttonSign {
    width: 100%;
    background-color: #4C4D52 !important;
    margin-top: 40px;
  }

  .labelStep{
    
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px;
    color: #5271FF !important;
    margin-bottom: 5px;
  }

  .bottomLine{
    background-color: #5271FF;
    width: 190px;
    height: 2px;
    margin-bottom: 20px;
  }

  .bottomText{
    
    color: #5D646E;
    font-size: 14px;
    line-height: 21px;
  }

  .linkLogin{
    
    font-size: 14px;
    line-height: 21px;
    color: #5271FF;
    text-decoration: none;
  }

  .description{
    
    font-size: 14px;
    line-height: 21px;
    color: #4C4D52;
    margin-bottom: 20px;
  }

  .textInputLabel {
    
    font-weight: 500;
    margin-bottom: 5px;
    color: #4C4D52;
  }
  
  .textInputInput {
    
  }
  
  .textInputRequired {
    color: #4C4D52 !important;
  }

  .signModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modalTitle{
    color: #4C4D52;
    font-size: 20px;
    font-weight: 900 !important;
    
  }

  .modalDesc{
    color: #8C8C8C;
    font-size: 16px;
    
    margin: 10px 0;
  }

  /* Responsive Styles */
@media (max-width: 1024px) {

  .content {
    flex-direction: column; 
    align-items: center;
  }

 
  .form {
    padding: 0 20px; 
  }

  .button-container {
    padding: 0 20px; 
  }

  .title {
    font-size: 20px; 
    line-height: 28px;
    margin-bottom: 12px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
  }

 
  .hideOnSmall {
    display: none;
  }

  .image {
    padding: 0;
    margin: 0;
    width: 100%; 
    max-width: 100%; 
  }

  .labelStep {
    font-size: 16px !important; 
    line-height: 20px;
  }

  .bottomLine {
    width: 180px; 
  }

  .button,
  .buttonSign {
    width: 100%; 
  }
}

@media (max-width: 768px) {
  .leftContainer{
    height: 800px;
  }

  .title {
    font-size: 18px;
    line-height: 26px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 16px;
  }

 
  .form {
    padding: 0 10px;
  }

  .button-container {
    padding: 0 10px;
  }


  .buttonSign {
    font-size: 14px; 
  }
  .textInput {
    --mantine-textinput-size: small;  
  }
}


@media (min-width: 769px) {
  .textInput {
    --mantine-textinput-size: medium;  
  }
}