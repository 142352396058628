.form-select {
  padding-right: 35px;
}

.card-body {
  .form-switch {
    .form-check-input {
      width: 2em;
      margin-left: -2.5em;
      border-radius: 2em;
    }
  }
}

.refresh {
  width: 32px;
  height: 32px;
  cursor: pointer;
  &:active {
    width: 28px;
    height: 28px;
  }
}
.modal-changepassword-inside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 696px;
}
.btn-changepassword {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 10px center;
  color: #1f2327;
  border-color: #e4e4e4;
  background-size: 12px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  letter-spacing: 1px;
  
  &:hover {
    border-color: #5d5fef;
    color: #fff;
    background-color: #5d5fef;
  }
}
.invite-new-user {
  text-transform: none !important;
  font-weight: 700 !important;
  background-color: #5271FF !important;
  font-size: 13px !important;
}
.error {
  background-color: rgba(220, 53, 69, 0.2);
  display: flex;
  align-items: center;
  font-size: 12px;
  
  margin-top: 5px;
  padding-left: 12px;
  border-radius: 3px;
  height: 25px;
}
