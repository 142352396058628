.steps {
    margin: 55px;
  }
// .step {
// border-color: #FFFFFF;
// }
.stepWrapper {
outline: #5271ff;
outline-offset: 0; 
}
.stepIcon {
    &[data-progress]{
        background-color: #5271ff;
    }
    &[data-completed]{
        background-color: #5271ff
    }
    // background-color: #5271ff !important; 
    color: #FFFFFF !important; 
    border-color: transparent !important;
    font-size: 18px;
    
}
.separator {
&[data-active]{
    outline: #5271ff;
    background-color: #5271ff;
    outline-offset: 0; 
}
}
.stepLabel {
font-size: 18px;
}
