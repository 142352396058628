/* variables */
$primary-color: #5271FF;
$secondary-color: #D8D8D8;
$color-black : #1F2327;
$color-gray: #ECECEC;
$color-green : #0ABA08;
$color-red : #FF5258;

main {
    .border-radius-0 {
        border-radius: 0;
    }




}

.card {
    margin-bottom: 30px;
    border: none;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
    height: calc(100% - 30px);

    .card-header {
        padding: 18px 20px;
        border: none;
        margin: 0;
        background-color: $color-gray;

        &:first-child {
            border-radius: 5px 5px 0 0;
        }
    }

    .card-body {
        padding: 20px;

        .formFile-control {
            img {
                width: 100%;
            }
        }

    }
}